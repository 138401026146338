
import { mapGetters, mapActions, mapMutations } from "vuex";
import filters from "@/mixins/filters";
// import onlyFiltersPrice from "@/apollo/queries/offer/onlyFiltersPrice";

export default {
	mixins: [filters],
	data() {
		return {
			ready: false,
			inputFrom: 0,
			inputTo: 0,

			testInputPrice: 0,
			testInputTo: 0,
			masks: {

				priceInput: {
					// mask: "*{1,20}",
					suffix: ' ₽',
					groupSeparator: " ",
					alias: "numeric",
					autoGroup: true,
					digits: 0,
					digitsOptional: false,
					clearMaskOnLostFocus: false,
					rightAlign: false,
					
					// max: this.inputTo
					// oncomplete: () => this.$emit('phoneMaskComplete'),
					// onincomplete: () => this.$emit('onincomplete'),
					// definitions: {
					// 	"*": {
					// 		validator: "[1234569]",
					// 	},
					// },
				}
			}
		}
	},
	props: {
		from: String,
		to: String,
		forFilter: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		...mapGetters({
			filters: 'filters/filters/filters',
			chosen: 'filters/filters/chosen',
			offers: 'filters/filters/offers',
			currentPrices: 'filters/filters/currentPrices'

		}),
		filterPrice() {
			return this.filters?.price || [0, 0]
		},
		getPriceOptions() {
			return {
				type: 'double',
				grid: false,
				realTime: true,
				step: 1,
				from: this.inputFrom,
				to: this.inputTo ? this.inputTo : this.filterPrice?.[1],
				min: this.filterPrice?.[0],
				max: this.filterPrice?.[1],
				prettify: this.my_prettify,
				onChange: (event) => {
					this.inputFrom = event.from
					this.inputTo = event.to
					this.setCurrentPrices([this.inputFrom, this.inputTo])
					this.setChosen({ key: 'priceFrom', value: this.inputFrom })
					this.setChosen({ key: 'priceTo', value: this.inputTo })
				},
				// onFinish: (event) => this.forFilter && this.sendPrice(event),


			}
		}
	},
	mounted() {
		// let im = new Inputmask(this.masks.priceInput);
		// im.mask(this.$refs.inputLeft__price);
		// im.mask(this.$refs.inputRight__price);




		this.changeInputs()
		this.ready = true;

	},
	watch: {

		inputFrom(value) {
			if (value) {
				let im = new Inputmask(this.masks.priceInput);
				im.mask(this.$refs.inputLeft__price);
			}
		},
		inputTo(value) {
			if (value) {
				let im = new Inputmask(this.masks.priceInput);
				im.mask(this.$refs.inputRight__price);
			}
		},
		currentPrices(value) {
			if (value[0] === 0 && value[1] === 0) {
				this.inputFrom = 0
				this.inputTo = 0
				if (this.$refs.inputRight__price.inputmask)
					this.$refs.inputRight__price.inputmask.remove()
				if (this.$refs.inputLeft__price.inputmask)
					this.$refs.inputLeft__price.inputmask.remove()
				this.$refs["range-price"].slider.update({ from: 0 })
				this.$refs["range-price"].slider.update({ to: this.filterPrice?.[1] })
			}
		}
	},

	methods: {
		...mapMutations({
			setCurrentCar: 'form/form-credit/SET_CURRENT_CAR',
			setChosen: 'filters/filters/SET_CHOSEN',
			setIsFilterClick: 'filters/filters/SET_IS_FILTER_CLICK',
			setTabComponent: 'modal/modal-choose/SET_TAB_COMPONENT',
			setCurrentPrices: 'filters/filters/SET_CURRENT_PRICES',

		}),
		...mapActions({
			request: 'filters/filters/request',
		}),
		my_prettify(n) {
			return n ? Number(n).toLocaleString('ru') + ' ₽' : ''
		},
		inp(value, tag) {
			if (tag === 'priceFrom') {
				this.inputFrom = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-price"].slider.update({
					from: value.replace(/\s/g, '').replace('₽', '')
				})
			}
			if (tag === 'priceTo') {
				this.inputTo = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-price"].slider.update({
					to: value.replace(/\s/g, '').replace('₽', '')
				})
			}
			let inputFrom = this.inputFrom
			let inputTo = this.inputTo
			this.setCurrentPrices([inputFrom, inputTo])
			this.setChosen({ key: 'priceFrom', value: inputFrom })
			this.setChosen({ key: 'priceTo', value: inputTo })
		},


		async sendPrice() {
			this.setIsFilterClick(true)
			await this.$router.push({ path: this.$route.fullPath, query: { price_from: this.inputFrom, price_to: this.inputTo } });
		},
		changeInputs() {
			if (this.$route.query.price_from && this.$route.query.price_to) {
				this.inputFrom = Number(this.$route.query.price_from)
				this.inputTo = Number(this.$route.query.price_to)
				this.setChosen({ key: 'priceFrom', value: this.inputFrom })
				this.setChosen({ key: 'priceTo', value: this.inputTo })
			} else {
				// this.inputFrom = Number(this.filterPrice?.[0])
				// this.inputTo = Number(this.filterPrice?.[1])
			}
			if (this.currentPrices[0] && this.currentPrices[1]) {
				if ((this.currentPrices[0] < this.filterPrice?.[0]) || (this.currentPrices[0] > this.filterPrice?.[1])) this.currentPrices[0] = this.filterPrice?.[0]
				if ((this.currentPrices[1] > this.filterPrice?.[1]) || (this.currentPrices[1] < this.filterPrice?.[0])) this.currentPrices[1] = this.filterPrice?.[1]

				this.inputFrom = Number(this.currentPrices[0])
				this.inputTo = Number(this.currentPrices[1])
				this.setChosen({ key: 'priceFrom', value: this.inputFrom })
				this.setChosen({ key: 'priceTo', value: this.inputTo })
			}
			if (this.currentPrices[0] === 0 && this.currentPrices[1] === 0) {
				this.inputFrom = Number(this.currentPrices[0])
				this.inputTo = Number(this.currentPrices[1])
			}






			if (this.$route.path === '/cars/before-1000') this.inputTo = 1000000
			this.setChosen({ key: 'priceFrom', value: this.inputFrom })
			this.setChosen({ key: 'priceTo', value: this.inputTo })


		},
		// async onlyFilterPriceRequest(assignVariables) {
		// 	try {
		// 		let response = await this.request({ query: onlyFiltersPrice, variables: assignVariables })
		// 		this.inputFrom = Number(response.data?.offerFilters?.price[0])
		// 		this.inputTo = Number(response.data?.offerFilters?.price[1])

		// 		this.setChosen({ key: 'priceFrom', value: this.inputFrom })
		// 		this.setChosen({ key: 'priceTo', value: this.inputTo })

		// 		await this.changingFilters(response.data?.offerFilters);
		// 	} catch (error) {
		// 		return this.$nuxt.error({ statusCode: 404, message: '404' })
		// 	}
		// },
		// async changingFilters(payload) {
		// 	await this.$store.commit('filters/filters/SET_FILTERS', payload)
		// 	await this.$store.commit('filters/filters/SET_ALL_CHOSEN', {})
		// },
	},
	// destroyed() {
	// 	this.changeInputs()
	// },


}
